<template>
  <div class="addImplement">
    <div class="addImplement-wrap">
      <div class="addImplement-top">
        <div>{{ addPtitle }}</div>
        <img @click="closefn" src="@/assets/images/pop01.png" alt="" />
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input">
          <span style="margin-right:12px">月份</span>
          <div>
            
            <!-- <el-select style="width:100%" v-model="yearMonth" clearable placeholder="请选择月份">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select> -->
                <el-date-picker
          v-model="yearMonth"
          type="month"
          placeholder="选择年月"
          :disabledDate="disabledDate"
          format="YYYY-MM"
          value-format="YYYY-MM"
        />
          </div>
        </div>
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input">
          <span>产生量</span>
          <div>
            
            <el-input v-model="inputs1"
                      maxlength='20'
                      show-word-limit
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      placeholder="请输入产生量"></el-input>
          </div>
        </div>
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input">
          <span>回收量</span>
          <div>
            
            <el-input v-model="inputs2"
                      maxlength='20'
                      show-word-limit
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      placeholder="请输入回收量"></el-input>
          </div>
        </div>
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input">
          <span>排放量</span>
          <div>
            
            <el-input v-model="inputs3"
                      maxlength='20'
                      show-word-limit
                      oninput="value=value.replace(/[^0-9.]/g,'')"
                      placeholder="请输入排放量"></el-input>
          </div>
        </div>
      </div>
      <!-- <div class="addImplement-content">
        <div class="addImplement-input flex2">
          <span>设置模块</span>
          <div class = "selectlist">
            <el-checkbox-group v-model="checkListselected">
              <template v-for = "(item,index) in checkList" :key = "index">
                <el-checkbox :label="item.ID">{{item.Name}}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </div>
      </div> -->
      <div class="addImplement-bottom">
        <button @click="closefn" class="def">取消</button>
        <button @click="AddExtractApplyfn" :class="{ disable: !inputs1 || !inputs2|| !inputs3 || !yearMonth}">
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import { GetMenuList, InsertOrUpdate_constructionWaste, GetRole } from "@/js/Construction";
export default {
  props: {
    addPfalse: Boolean,
    bonus: Number,
    addPtitle: String,
    editdata: Object,
  },
  setup(props, { emit }) {
    // console.log(props,53)
    const state = reactive({
      // checkListselected:[],
      checkList: [],
      disabledDate :(time) =>{
         return time.getTime() > Date.now();
    },
      yearMonth:'',
      options:[
        {
          value:'一月',
          label:'一月'
        },{
          value:'二月',
          label:'二月'
        },{
          value:'三月',
          label:'三月'
        },{
          value:'四月',
          label:'四月'
        },{
          value:'五月',
          label:'五月'
        },{
          value:'六月',
          label:'六月'
        },{
          value:'七月',
          label:'七月'
        },{
          value:'八月',
          label:'八月'
        },{
          value:'九月',
          label:'九月'
        },{
          value:'十月',
          label:'十月'
        },{
          value:'十一月',
          label:'十一月'
        },{
          value:'十二月',
          label:'十二月'
        },
      ],
      inputs1: "", //
      inputs2:"",
      inputs3:"",
      value: "",
      Primarydata: [],
      Primaryobj: null,
      adding: false,
      num: 0,
      showstyle: false,
      addtitle: "",
      editid: "",
      cannext: false,
    });
    const closefn = () => {
      emit("addPfalsefn");
    };
    // const gr = () =>{
    //   let datas = {
    //     id:state.editid
    //   }
    //   GetRole(datas).then((res)=>{
    //     console.log(res);
    //     let {Code,Data,Message} = res.data;
    //     if(Code == 1 && Data){
    //       state.checkListselected = Data.Rolemenudata;
    //       state.cannext = true;
    //     }else{
    //       ElMessage({
    //         showClose: true,
    //         message: Message,
    //         type: "error",
    //       });
    //     }
    //   })
    // }
    const cdata = getStorage("bscdata");
    // const gml = ()=>{
    //   GetMenuList().then((res)=>{
    //     console.log(res);
    //     let {Code,Data,Message} = res.data;
    //     if(Code == 1 && Data){
    //       state.checkList = Data;
    //     }else{
    //       ElMessage({
    //         showClose: true,
    //         message: Message,
    //         type: "error",
    //       });
    //     }
    //   })
    // }
    const methods = {

      // checkChange:()=>{
      //   console.log(state.checkListselected);
      // },
      AddExtractApplyfn: () => {
        if (!state.inputs1||!state.inputs2||!state.inputs2||!state.yearMonth) {
          return;
        }
        let datas = {
          years:dayjs(state.yearMonth).format('YYYY-MM'),
          discharge:state.inputs1,
          recycled:state.inputs2,
          emissions:state.inputs3,
          insertUser:cdata.uid,
          projectId: cdata.pid,
        };
        if (state.editid) {
          datas.id = state.editid;
        }
        InsertOrUpdate_constructionWaste(datas).then((res) => {
          console.log(res);
          let { Code, Message,Data } = res.data;
          if (Code == 1) {
            emit("GetProjectBigEventList");
            emit("addPfalsefn");
            ElMessage({
              showClose: true,
              message: Message,
              type: "success",
            });
          } else {
            ElMessage({
              showClose: true,
              message: Data,
              type: "error",
            });
          }
        });
      },
    };
    onMounted(() => {
      console.log(props, 142);
      state.addPtitle = props.addPtitle;
      
      if (state.addPtitle == "编辑建筑垃圾") {
        state.inputs1 = props.editdata.discharge;
        state.inputs2 = props.editdata.recycled;
        state.inputs3 = props.editdata.emissions;
        state.yearMonth = props.editdata.years;
        state.editid = props.editdata.ID;
        // gr();
      }

      // gml();
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addImplement {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addImplement-wrap {
    width: 576px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addImplement-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }
  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }
  .addImplement-content {
    width: 100%;
    padding: 0 20px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addImplement-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      &:deep(.el-input) {
        width: 380px;
      }
      &.flex2 {
        align-items: flex-start;
        .el-checkbox-group {
          .el-checkbox {
            margin-bottom: 10px;
          }
        }
      }
      span {
        display: inline-block;
        padding-right: 11px;
        box-sizing: border-box;
        word-break: keep-all;
      }
    }

    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addImplement-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addImplement-add {
    margin-left: 109px;
    margin-bottom: 20px;
    .addImplement-btn {
      width: 128px;
      height: 32px;
      background: rgba(0, 180, 255, 0.1);
      border-radius: 4px;
      border: 1px solid #0083ff;
      font-size: 12px;
      color: #0083ff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        box-sizing: border-box;
      }
    }
  }

  .addImplement-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #adbaae;
      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      // &:nth-child(2) {
      background: #027aff;
      color: #fff;
      // }
      &.def {
        background: #fff;
        color: #adbaae;
      }
      &.disable {
        background: #fff;
        color: #adbaae;
        cursor: no-drop;
        opacity: 0.5;
      }
    }
  }
}
</style>