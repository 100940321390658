import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 添加工程图片
export const AddProjectPicture=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/AddProjectPicture`,
        data:datas
    })
}

// 新增修改项目Logo
export const UpdateProjectLogo=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/UpdateProjectLogo`,
        data:datas
    })
}

// 获取工程图片
export const GetProjectLogo=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/GetProjectLogo`,
        params:datas
    })
}

// 添加工程视频
export const AddProjectVideo=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/AddProjectVideo`,
        data:datas
    })
}

// 添加工程概述
export const UpdateProjectOverview=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/UpdateProjectOverview`,
        data:datas
    })
}


// 删除项目图片
export const DeleteProjectPicture=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/DeleteProjectPicture`,
        params:datas
    })
}

// 删除项目视频
export const DeleteProjectVideo=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/DeleteProjectVideo`,
        params:datas
    })
}

// 删除文件
export const DelPictureList=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/WeatherForecast/DelPictureList`,
        data:datas
    })
}

// 获取工程概述
export const GetProjectOverview=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/GetProjectOverview`,
        params:datas
    })
}

// 获取工程图片
export const GetProjectPictureList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/GetProjectPictureList`,
        params:datas
    })
}

// 获取工程视频
export const GetProjectVideoList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/GetProjectVideoList`,
        params:datas
    })
}



